export default {
  name: 'TabSwitch',
  emits: ['input'],
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: null,
    },
  },
  methods: {
    onItemClick(item) {
      this.$emit('input', item.value)
    },
  },
}
